<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';
	import GlobalVue from '../helper/Global.vue'

	export default {
		extends: BaseVue,
		data() {
			return {
				static_hero:{},
				static_cape:{},
				static_belli:{},
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
			$(document).ready(() => {
				AOS.init({
					anchorPlacement: 'top-bottom'
				});
				$('#slider_content').css('top','194.667px')
			})
			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				});

				setTimeout(() => {
					var topValue = $("#slider").height() / 3;
					let heroMobile = $("#slider [data-hero-mobile]").attr("data-hero-mobile");
					if ($(window).width() < 992) {
						$("#slider [data-hero-mobile]").css("background-image", "url('" + heroMobile + "')");
						// var topValue = $("#slider").height() / 4;
					}
					// $("#slider").css("height", $(window).height() + "px");
					$(".slider-caption").css("top", topValue + "px");
				}, 300);
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
				this.refreshMeta()
			});

			this.getHeroImages()
			this.staticCape()
			this.staticBelli()
		},
		methods: {
			getHeroImages() {
				this.$root.topProgress.done()
				Gen.apirest("/static-hero", {id:1}, (err,resp)=>{
					if(err) console.log(err)
					this.static_hero = resp.data
				})
			},
			staticCape() {
				this.$root.topProgress.done()
				Gen.apirest("/static-content", {id:1}, (err,resp)=>{
					if(err) console.log(err)
					this.static_cape = resp.data
				})
			},
			staticBelli() {
				this.$root.topProgress.done()
				Gen.apirest("/static-content", {id:2}, (err,resp)=>{
					if(err) console.log(err)
					this.static_belli = resp.data
				})
			},
		},
		watch: {
		}
	};
</script>
<template>
	<div Content>

		<section id="slider" class="slider-element force-full-screen full-screen" data-aos="zoom-out"
			data-aos-duration="1000">

			<div class="force-full-screen full-screen"
				:style="'background-image: url('+uploader(static_hero.image_desktop)+');background-position: 50% 0;'"
				:data-hero-mobile="uploader(static_hero.image_mobile)">

				<div class="container clearfix">
					<div class="slider-caption custom-caption-pos">
						<h3 data-animate="fadeInDown" :style="'color:'+static_hero.header_color">{{static_hero.title_header}}</h3>
						<p data-animate="fadeInUp" :style="'color:'+static_hero.subheader_color" data-delay="400" class="d-none d-lg-block">{{static_hero.title_subheader}}</p>
						<router-link v-if="$root.subscribeActive=='true'" :to="{name:'Subscribe'}" data-animate="fadeInUp" data-delay="800" class="mt-4 mt-lg-0 button button-red button-large nobottommargin">{{web.btn_subscribe_now}}</router-link>
					</div>
				</div>

			</div>

			<a href="javascript:;" data-scrollto="#content" class="scroll-down">
				<i class="icon-angle-down"></i>
			</a>
		</section>

		<div class="clearfix"></div>

		<div id="content">
			<section class="section notopmargin nobottommargin padbothalf">
				<div class="row justify-content-center align-items-center prod-highlight">
					<div class="col-md-4 d-none d-lg-block" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500" data-aos-anchor="#anc1">
						<img v-if="static_cape.show_image=='Y'" :src="uploader(static_cape.image1)" alt="" class="side-img si-left">
					</div>
					<div class="col-lg-4 middle-desc" id="anc1" :style="'background-image: linear-gradient(45deg, rgba(239, 239, 239, .45), rgba(239, 239, 239, .45)), linear-gradient(45deg, rgba(239, 239, 239, .45), rgba(239, 239, 239, .45)), url('+uploader(static_cape.image1)+'), url('+uploader(static_cape.image2)+');'">
						<div class="our-prod" data-aos="fade-up" data-aos-duration="1500">
							<h2 class="op-title">
								{{static_cape.title}}
							</h2>
							<p class="op-desc">
								{{static_cape.description}}
							</p>
							<router-link v-if="static_cape.have_button=='Y'" :to="{name:'CDCollection'}" class="button button-red">{{web.btn_see_our_collection}}</router-link>
						</div>
					</div>
					<div class="col-md-4 d-none d-lg-block" data-aos="fade-left" data-aos-duration="1500" alt="" data-aos-delay="500" data-aos-anchor="#anc1">
						<img v-if="static_cape.show_image2=='Y'" :src="uploader(static_cape.image2)"
							class="side-img si-right">
					</div>
				</div>
			</section>
			<section class="section notopmargin nobottommargin padtophalf">
				<div class="row justify-content-center align-items-center prod-highlight">
					<div class="col-md-4 d-none d-lg-block">
						<img v-if="static_belli.show_image=='Y'" :src="uploader(static_belli.image1)" alt="" class="side-img si-left" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500"  data-aos-anchor="#anc2">
					</div>
					<div class="col-lg-4 middle-desc" id="anc2" :style="'background-image: linear-gradient(45deg, rgba(239, 239, 239, .45), rgba(239, 239, 239, .45)), linear-gradient(45deg, rgba(239, 239, 239, .45), rgba(239, 239, 239, .45)), url('+uploader(static_belli.image1)+'), url('+uploader(static_belli.image2)+');'">
						<div class="our-prod" data-aos="fade-up" data-aos-duration="1500">
							<h2 class="op-title">
								{{static_belli.title}}
							</h2>
							<p class="op-desc">
								{{static_belli.description}}
							</p>
							<router-link v-if="static_belli.have_button=='Y'" :to="{name:'BLCollection'}" class="button button-red">{{web.btn_see_our_collection}}</router-link>
						</div>
					</div>
					<div class="col-md-4 d-none d-lg-block">
						<img v-if="static_belli.show_image2=='Y'" :src="uploader(static_belli.image2)" alt="" class="side-img si-right" data-aos="fade-left" data-aos-duration="1500" data-aos-delay="500"  data-aos-anchor="#anc2">
					</div>
				</div>
			</section>
		</div>
	</div><!-- #wrapper end -->
	<!-- </div> -->
</template>